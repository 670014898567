<ng-container *ngIf="!isDesktop else desktop">
  <a routerLink="/{{ data.inAppUrl }}" *ngIf="data.page" routerLink="/{{ data.page.category }}/{{ data.page.localizedName }}" (click)="itemClickedParent()">
    <mat-list-item>
      <mat-icon mat-list-icon>{{data.iconName}}</mat-icon>
      <span class="h16">{{ data.name }}</span>
    </mat-list-item>
  </a>
  <a routerLink="/{{ data.inAppUrl }}" *ngIf="data.inAppUrl" (click)="itemClickedParent()">
    <mat-list-item>
      <mat-icon mat-list-icon>{{data.iconName}}</mat-icon>
      <span class="h16">{{ data.name }}</span>
    </mat-list-item>
  </a>
  <mat-divider></mat-divider>
</ng-container>

<!-- <ng-template #desktop>
  <mat-list-item *ngIf="data.page" class="desktop-menu-item" [topDrawerRouterLink]="[data.page.category,data.page.localizedName]">
    <span class="desktop-menu-text">{{ data.name }}</span>
  </mat-list-item>
  <mat-list-item *ngIf="data.inAppUrl" class="desktop-menu-item" topDrawerRouterLink="{{ data.inAppUrl }}">
    <span class="desktop-menu-text">{{ data.name }}</span>
  </mat-list-item>
</ng-template> -->

<ng-template #desktop>
  <a [topDrawerRouterLink]="[data.page.category,data.page.localizedName]" *ngIf="data.page">
    <mat-list-item class="desktop-menu-item">
      <span class="desktop-menu-text">{{ data.name }}</span>
    </mat-list-item>
  </a>
  <a topDrawerRouterLink="{{ data.inAppUrl }}" *ngIf="data.inAppUrl">
    <mat-list-item class="desktop-menu-item">
      <span class="desktop-menu-text">{{ data.name }}</span>
    </mat-list-item>
  </a>
</ng-template>
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SwiperBaseComponent } from '../../swiper-base/swiper-base.component';

@Component({
  selector: 'app-swiper-game-categories-head',
  templateUrl: './swiper-game-categories-head.component.html',
  styleUrls: ['./swiper-game-categories-head.component.scss']
})
export class SwiperGameCategoriesHeadComponent extends SwiperBaseComponent implements OnInit {

  @Input() iconUrl: string;
  @Input() displayName: string;
  @Input() link:string;

  constructor(
    
  ) { super() }

  ngOnInit(): void {
  }

  swipeToFront() {
    this.onSwipeToFront.emit();
  }

  swipeToBack() {
    this.onSwipeToBack.emit();
  }

}

<div class="dialog-container">
    <div class="content">
        <div class="notification-header" i18n>notifications</div>
    </div>
    <div class="notification-list">
        <div class="notification" *ngFor="let item of notifications; let last = last;" >
            <div fxLayoutAlign="center center"  fxLayout="row">
                <div class="image"><img [src]="item.thumbnail?.url" /></div>
                <span fxFlex></span>
                <div class="info">
                    <div class="name">{{item.text}}</div>
                    <div class="description" i18n>free</div>
                </div>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
        </div>
    </div>
</div>
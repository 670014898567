import { Component, EventEmitter, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-download-infobar',
  templateUrl: './download-infobar.component.html',
  styleUrls: ['./download-infobar.component.scss']
})
export class DownloadInfobarComponent extends BaseComponent {
  
  @Output() close = new EventEmitter();
  @Output() showInstall = new EventEmitter();
  ngOnInit(): void {
    console.log('init')
  }

  closeInfo() {
    this.close.emit();
  }

  gotoInstall() {
    this.showInstall.emit();
  }

}

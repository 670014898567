import { Component, Inject, LOCALE_ID } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { SystemPage, SystemPageResponse } from 'src/app/apollo/models/base-models';
import { SYSTEM_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { LimitEntry, LimitType } from 'src/app/services/profile/profile.models';
import { ResponsibleGamingService } from 'src/app/services/profile/responsible-gaming.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { BasePageComponentWithDialogs } from '../../base-page/base-page.component';

@Component({
  selector: 'app-responsible-gaming',
  templateUrl: './responsible-gaming.component.html',
  styleUrls: ['./responsible-gaming.component.scss']
})
export class ResponsibleGamingComponent extends BasePageComponentWithDialogs {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private apollo: Apollo,
    private sanitizer: DomSanitizer,
    private responsibleGamingService: ResponsibleGamingService,
    private formBuilder: UntypedFormBuilder,
    protected detectDesktopService: DetectDeviceService,
    public errorDialog: MatDialog,
    private loaderService: LoaderService
  ) {
    super(errorDialog, detectDesktopService)
  }

  page: SystemPage;
  body: SafeHtml;
  wagerLimits: LimitEntry[];
  depositLimits: LimitEntry[];
  lossLimits: LimitEntry[];
  isEditMode: boolean;
  selectedElement: LimitEntry;

  limitsForm = this.formBuilder.group({
    deposit_D: ['', []],
    deposit_W: ['', []],
    deposit_M: ['', []],
    wager_D: ['', []],
    wager_W: ['', []],
    wager_M: ['', []],
    lost_D: ['', []],
    lost_W: ['', []],
    lost_M: ['', []],
    game_session_time_D: ['', []],
    reality_check_interval_D: ['', []]
  });

  public displayedColumns: string[] = ['date', 'amount', 'value', 'pending'];
  limitNameTranslation = {
    D: $localize`:@@daily:daily`,
    W: $localize`:@@weekly:weekly`,
    M: $localize`:@@monthly:monthly`,
  }

  unlimitedTest = $localize`:@@limitsEmpty:unlimited`;

  ngOnInit(): void {
    this.loaderService.show();
    this.apollo.watchQuery<SystemPageResponse>({
      query: SYSTEM_PAGE_CONTENT_QUERY,
      variables: {
        pageName: "responsible-gaming",
        locale: this.locale
      }
    }).valueChanges.pipe(takeUntil(this.unsubscribe),).subscribe((resp) => {
      this.page = resp.data.systemPage;
      this.body = this.sanitizer.bypassSecurityTrustHtml(this.page.content.html);

      this.initializeFields();
    })
  }

  initializeFields() {
    this.responsibleGamingService.getLimitsValues().pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      resp.limits.map(item => {
        const formEntry = this.limitsForm.get(item.type + '_' + item.period);
        // console.log(item.type + '_' + item.period, formEntry)
        if (item.value > 0)
          formEntry.setValue(item.value);
      })

      this.wagerLimits = resp.limits.filter(item => item.type === LimitType.wager);
      this.depositLimits = resp.limits.filter(item => item.type === LimitType.deposit);
      this.lossLimits = resp.limits.filter(item => item.type === LimitType.lost);
      this.loaderService.hide();

    })
  }

  editValue(element: LimitEntry) {
    this.selectedElement = element;
  }

  onSubmit() {
    this.selectedElement = null;
    if (this.limitsForm.disabled) return;
    this.limitsForm.disable();
    this.responsibleGamingService.setLimits(this.limitsForm).pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.initializeFields();
      this.openSuccessDialog();
    }, err => {
      this.limitsForm.enable();
      if (err.error?.error_message) {
        this.openErrorDialog(err.error.error_message);
      }
      else if (err.error) {
        this.openErrorDialog(err.error.error_message);
      }
      else {
        this.openErrorDialog();
      }
    });
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';

@Component({
  selector: 'app-swiper-base',
  templateUrl: './swiper-base.component.html',
  styleUrls: ['./swiper-base.component.scss']
})
export class SwiperBaseComponent extends BasePageComponent implements OnInit {

  @Output() onSlideClick = new EventEmitter<any>();
  
  @Output() onSwipeToFront = new EventEmitter<any>();
  @Output() onSwipeToBack = new EventEmitter<any>();

  constructor() { super() }

  ngOnInit(): void {

  }

}

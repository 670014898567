import { Component, EventEmitter, Input, Output, Inject, LOCALE_ID, AfterViewInit } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { InitialDataService } from 'src/app/services/page/initial-data.service';
import { take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { gameTrx, liveCasinoTrx, signUpTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { UserMessagesService } from 'src/app/services/user-messages/user-messages.service';
import { Language } from 'src/app/apollo/models/base-models';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/global.tokens';
import { UploadDocumentsService } from 'src/app/services/profile/upload-documents.service';
import { sectionsData, DocumentData, UserDocumentsResponse, SectionCard, Status } from 'src/app/services/profile/upload-documents.models';
import { PendingWithdrawalService } from 'src/app/services/payment/withdrawal/pending-withdrawal.service';
import { PendingWithdrawalList } from 'src/app/services/payment/withdrawal/withdrawal.models';



@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent extends BasePageComponent implements AfterViewInit {


  liveCasinoRoute: string = `/${this.translationConfig.getTranslation(liveCasinoTrx)}`;
  slot: string = `/${this.translationConfig.getTranslation(gameTrx)}`;
  selectedLanguage: Language;

  constructor(
    public initialData: InitialDataService,
    private router: Router,
    protected loginStatus: LoginStatusService,
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    protected licenceService: LicenceService,
    private userMessagesService: UserMessagesService,
    private documentService: UploadDocumentsService,
    private pendingListService: PendingWithdrawalService,
    private cookie: CookieService,
    @Inject(HOST_NAME) public hostName,

    @Inject(LOCALE_ID) public locale

  ) { super() }
  isLogin: boolean = false;
  _isVisible: boolean = false;
  unreadMessages: number = 0;
  public userDocumentData: SectionCard[];
  hasDocumentRequest: boolean;
  pendingList: PendingWithdrawalList;


  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  childData: any;

  ngOnInit(): void {
    this.loginStatus.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.isLogin = resp;
  
      if (this.isLogin) {
        this.userMessagesService.unreadMessagesCount.pipe(take(1)).subscribe((unreadCount) => {
          this.unreadMessages = unreadCount;
        });
        this.documentService.updateUserDocuments();
        this.documentService.getUserDocuments().pipe(takeUntil(this.unsubscribe)).subscribe((userDocumentsResponse: UserDocumentsResponse) => {
          if (userDocumentsResponse) {
            this.userDocumentData = this.documentService.fillSectionCards(sectionsData, userDocumentsResponse, this.documentService.getSectionsCookie());
            this.hasDocumentRequest = !!this.userDocumentData.find((item) => !(item.status === this.documentService.translateStatus(Status.Approved) || item.status === this.documentService.translateStatus(Status.NotRequired)) && !item.is_hide);
          }
        });
  
        setTimeout(() => { // Side Nav count notifications api calls here
          this.pendingWithdrawalCheck();
        }, 0);
      }
  
      this.childData = this.initialData.fetchData().pipe(take(1)).subscribe(resp => {
        this.childData = resp;
      });
      this.loginStatus.getLoginPartialStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
        this.isLogin = resp;
      });
      this.childData = this.initialData.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
        this.childData = resp;
        this.selectedLanguage = resp.languages.filter(item => {
          return item.name === this.locale;
        })[0];
      });
    });
  }

  setCookie($event: any) {
    const language = $event.value;
    this.cookie.set('language', language, 2592000, '/', this.hostName);
    window.location.href = '/' + language + '/';
  }

  @Output() closeNav = new EventEmitter();

  callLinkClickedParent(): void {
    this.closeNav.next("");
  }

  signup() {
    this.router.navigate([this.router.url, this.translationConfig.getTranslation(signUpTrx)]);
    this.callLinkClickedParent();
  }

  pendingWithdrawalCheck() {
    if(this.isLogin) {
      this.pendingListService.getList().subscribe();
      this.pendingListService.pendingWithdrawalsCount$.pipe(takeUntil(this.unsubscribe)).subscribe({
        next: (resp) => {
          this.pendingList = resp;
        },
        error: (error) => {
          console.log(error)
        }
      });
      this.userMessagesService.unreadMessagesCount.pipe(takeUntil(this.unsubscribe)).subscribe((unreadCount) => {
        this.unreadMessages = unreadCount;
      });
    }
  }

  ngAfterViewInit(): void {
     // Side Nav count notifications api calls here
      this.pendingWithdrawalCheck(); 
  }

}

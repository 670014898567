import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { InitialDataService } from 'src/app/services/page/initial-data.service';
import { takeUntil } from 'rxjs/operators';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/global.tokens';
import { Location } from '@angular/common';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';
import { Language } from 'src/app/apollo/models/base-models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BasePageComponent implements AfterViewInit {

  constructor(
    public initialData: InitialDataService,
    protected location: Location,
    public deviceDetectService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    protected cookie: CookieService,
    protected manipulateHeaderService: HeadManipulateService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale,
  ) { super() }


  childData: any;
  currentUrl: string;
  currentUrlWithTranslation: string;
  selectedLanguage: Language;
  affiliateUrl:string;
  // @ViewChild('metaend') metaEndElement: ElementRef;

  initializeSeoHandlers() {
    this.location.onUrlChange((url) => {
      let urlArray = url.split('/');
      if (/^([a-z]){2}(-([aA-zZ]){2}){0,1}$/.test(urlArray[1])) {
        urlArray = urlArray?.slice(2, urlArray.length);
      }

      this.currentUrlWithTranslation = urlArray.join('/');
      this.currentUrlWithTranslation = this.currentUrlWithTranslation?.replace(/\/+$/, "");

      this.currentUrl = this.translationConfig.translateToOriginal(this.currentUrlWithTranslation);
      this.currentUrl = this.currentUrl?.replace(/\/+$/, "");

      const translatedUrls = this.translationConfig.translateUrlToAllLanguages(this.currentUrl);
      this.manipulateHeaderService.removeLinks();
      translatedUrls.forEach((value, lang) => {
        this.manipulateHeaderService.createLinkNode(lang.toLowerCase(), `https://${this.hostName}/${lang}/${value || ''}`)
      })

    })
  }

  ngOnInit(): void {
    this.initializeSeoHandlers()
    this.childData = this.initialData.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.childData = resp;
      this.selectedLanguage = resp.languages.filter(item => {
        return item.name === this.locale
      })[0];
    })

    if(environment.affiliateProgram) {
      this.affiliateUrl = environment.affiliateProgram;
    }

  }

  ngAfterViewInit(): void {

  }

  setCookie(language: string) {
    this.cookie.set('language', language, 2592000, '/', this.hostName);
  }

}

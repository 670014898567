import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-top-menu-item',
  templateUrl: './top-menu-item.component.html',
  styleUrls: ['./top-menu-item.component.scss']
})
export class TopMenuItemComponent extends BasePageComponent {

  constructor() { super() }

  @Input() isDesktop = false;

  @Output() closeEvent = new EventEmitter();

  itemClickedParent(): void {
    this.closeEvent.next("");
  }

  ngOnInit(): void {
  }

}

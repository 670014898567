import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';

@Component({
  selector: 'app-install-popup',
  templateUrl: './install-popup.component.html',
  styleUrls: ['./install-popup.component.scss']
})
export class InstallPopupComponent extends BaseComponent {
  @Input() pwaEvent: any;
  @Output() showInstall = new EventEmitter();
  @Output() hideInstall = new EventEmitter();
  ngOnInit(): void {

  }

  async initInstall() {
    let deferredPrompt;
    console.log("init install", this.pwaEvent);
    deferredPrompt = this.pwaEvent;
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    if (outcome === 'accepted') {
      this.hideInstall.emit();
      console.log('User accepted the install');
    } else if (outcome === 'dismissed') {
      this.hideInstall.emit();
      console.log('User dismissed the install');
    }
  }

  userWebVersion() {
    this.hideInstall.emit();
    window.location.href = './redirect';
    window.location.reload();
  }

}

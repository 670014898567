import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { BasePageComponent } from '../../base-page/base-page.component';
import { clientAreaMainTrx, clientAreaTrx } from '../../../router-translation.labels';
import { AvatarService } from 'src/app/services/profile/avatar.service';

@Component({
  selector: 'app-desktop-top-profile-info',
  templateUrl: './desktop-top-profile-info.component.html',
  styleUrls: ['./desktop-top-profile-info.component.scss']
})
export class DesktopTopProfileInfoComponent extends BasePageComponent {

  constructor(
    private loginStatusService: LoginStatusService,
    private gamificationLevelService: GamificationLevelService,
    protected translationConfig: TranslationConfig,
    public avatarService:AvatarService,

  ) { super() }

  gamificationPercentFill: number;
  gamificationLevel: number;
  showGamification: boolean;
  avatar: string;

  username: string;
  profileUrl:string[] = [this.translationConfig.getTranslation(clientAreaTrx),this.translationConfig.getTranslation(clientAreaMainTrx)];

  ngOnInit(): void {

    this.loginStatusService.getLoginStatus().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.username = resp.username;
      this.avatarService.getAvatar().pipe(takeUntil(this.unsubscribe)).subscribe(data=>this.avatar=data);

    })

    this.gamificationLevelService.getGamification(true).pipe(takeUntil(this.unsubscribe)).subscribe((resp) => {
      this.gamificationPercentFill = resp.turnover / resp.levelEndsTurnover * 100
      this.gamificationLevel = resp.levelNumber
      this.showGamification = true;
    })
  }

}

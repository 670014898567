import { Apollo } from 'apollo-angular';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { GameCategory, GameItem } from 'src/app/apollo/models/base-models';
import { LastPlayedService } from 'src/app/services/profile/last-played.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { takeUntil } from 'rxjs/operators';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { LICENCE } from 'src/app/global.tokens';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';


@Component({
  selector: 'app-last-played-full',
  templateUrl: './last-played-full.component.html',
  styleUrls: ['./last-played-full.component.scss']
})
export class LastPlayedFullComponent extends BasePageComponent {

  constructor(
    public lastPlayedService: LastPlayedService,
    private toggleBackService: ToggleMenuBackService,
    public detectDeviceService: DetectDeviceService,
    private apollo: Apollo,
    private frontPageGamesService: FrontPageGamesService,
    @Inject(LICENCE) public licence: string,
    @Inject(LOCALE_ID) public locale: string,

  ) { super() }

  games: GameItem[];
  categories: GameCategory[];

  ngOnInit(): void {
    this.toggleBackService.showBack();
    this.lastPlayedService.getLastPlayed().pipe(takeUntil(this.unsubscribe)).subscribe(
      resp => {
        this.games = resp;
      }
    )
    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.categories = response.pagesCategories[0]?.categories;
      // if (isPlatformServer(this.platformId)) {
      //   this.categories = this.categories.slice(0, 1);
      // }
    });
  }
}

import { Component } from '@angular/core';
import { Breadcrumb } from '../../../services/breadcrumbs/breadcrumb.model';
import { BreadcrumbService } from '../../../services/breadcrumbs/breadcrumb.service';
import { takeUntil } from 'rxjs';
import { BasePageComponent } from '../../base-page/base-page.component';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends BasePageComponent {
  public breadcrumbs: Breadcrumb[];

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbsObservable.pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.breadcrumbs = resp;
    });

  }
}

<ng-container *ngIf="category">
    <app-swiper (onSlideClick)="onGameClick($event)" 
        [swiperHead]="headTemplate" 
        [swiperBody]="bodyTemplate" 
        [swiperConfig]="gameSwiperConfig"
    > 

        <ng-template #headTemplate>
            <app-swiper-game-categories-head #head [displayName]="category.displayName" [iconUrl]="category.icon.url" [link]="link"/>
        </ng-template>

        <ng-template #bodyTemplate>
            <ng-container *ngFor="let game of category.game">
                <app-swiper-game-categories-body #slide [game]="game"/>
            </ng-container>
        </ng-template>

    </app-swiper>
</ng-container>
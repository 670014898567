import { AfterViewInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, Renderer2, ViewChild } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SwiperConfiguration } from '../../swiper-config.model';
import { Subscription, filter, map, takeUntil } from 'rxjs';
import { throttle, debounce } from 'lodash';

@Component({
  selector: 'app-swiper-body',
  templateUrl: './swiper-body.component.html',
  styleUrls: ['./swiper-body.component.scss']
})
export class SwiperBodyComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('swiperBodyContainer') swiperContainer!: ElementRef;
  @ViewChild('swiperBodyWrapper') swiperWrapper!: ElementRef;
  @ViewChild('swiperBodyWrapper', { read: ElementRef }) swiperWrapperElement!: ElementRef;

  @Input() swiperConfig: SwiperConfiguration;

  @Input() onSwipeToFront!: EventEmitter<any>;
  @Input() onSwipeToBack!: EventEmitter<any>;

  @Output() clickDebounceTimeout = new EventEmitter<any>();


  private totalSlidesWidth = 0;
  private swiperContainerWidth = 0;
  private slidesWidth: number[] = [];

  private requestAnimationFrameId: number;
  private isDragging = false;
  private startX = 0;
  private delta = 0;
  public translate = 0;

  private mouseDownTime = 0;
  private readonly maxClickDuration = 200;
  private debounceTimeout: any;

  private currentSlide = 0;
  private slideAmount = 0;

  throttledMove: (event: MouseEvent | TouchEvent) => void;
  debouncedCalculateTotalWidth = debounce(() => this.calculateTotalWidth(), 300);

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    super();

    // Throttle the mouse / touch move event handler
    this.throttledMove = throttle((event: MouseEvent | TouchEvent) => {
      if (!this.isDragging) return;
      const currentX = event instanceof MouseEvent ? event.pageX : event.touches[0].pageX;

      this.delta = currentX - this.startX;
      this.startX = currentX;
      this.translate += this.delta;

      if (this.requestAnimationFrameId) {
        cancelAnimationFrame(this.requestAnimationFrameId);
      }

      this.requestAnimationFrameId = requestAnimationFrame(() => this.processMove());
    }, 4); // 16ms for 60fps
  }

  ngOnInit() {
    if (this.onSwipeToFront) {
      this.onSwipeToFront.subscribe((event) => {
        this.swipeToFront();
      });
    }

    if (this.onSwipeToBack) {
      this.onSwipeToBack.subscribe((event) => {
        this.swipeToBack();
      });
    }

  }

  ngAfterViewInit() {
    this.swiperContainer.nativeElement.addEventListener('mousedown', this.handleStart.bind(this));
    this.swiperContainer.nativeElement.addEventListener('mousemove', this.handleMove.bind(this));
    this.swiperContainer.nativeElement.addEventListener('mouseup', this.handleEnd.bind(this));
    this.swiperContainer.nativeElement.addEventListener('mouseleave', this.handleEnd.bind(this));

    this.swiperWrapper.nativeElement.addEventListener('touchstart', this.handleStart.bind(this));
    this.swiperContainer.nativeElement.addEventListener('touchmove', this.handleMove.bind(this), { passive: false });
    this.swiperWrapper.nativeElement.addEventListener('touchend', this.handleEnd.bind(this));

    this.setConfig(this.swiperConfig);
    this.checkImagesLoaded();
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* Mouce / Touch Events Handling */

  handleStart(event: MouseEvent | TouchEvent) {
    this.isDragging = true;
    this.startX = event instanceof MouseEvent ? event.pageX : event.touches[0].pageX;
    this.mouseDownTime = Date.now();
  
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'cursor', 'grabbing');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'touch-action', 'pan-x');
  }

  handleMove(event: MouseEvent | TouchEvent) {
    if (this.isDragging) {
      event.preventDefault();
      this.throttledMove(event);
    }
  }

  handleEnd() {
    this.isDragging = false;

    if (this.swiperConfig.sticky) {
      this.processStickyMove();
    }
  
    const endTime = Date.now();
    if (endTime - this.mouseDownTime < this.maxClickDuration) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
  
      this.debounceTimeout = setTimeout(() => {
        this.clickDebounceTimeout.emit();
      }, 200);
    }
  
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'cursor', 'grab');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-out');
    this.renderer.removeStyle(this.swiperWrapper.nativeElement, 'touch-action');
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* Mouse / Touch process */
  totalSlideWidth(to: number) {
    return this.slidesWidth.slice(0, to + 1).reduce((acc, val) => acc + val, 0) + parseInt(this.swiperConfig.swiperBodyWrapper.gap) * (to + 1);
  }

  calculateCurrentSlide(translate: number) {
    if (-translate >= this.totalSlideWidth(this.currentSlide)) {
      this.currentSlide++;
    } else if (-translate < this.totalSlideWidth(this.currentSlide - 1)) {
      this.currentSlide--;
    }

    //console.log('this.currentSlide', this.currentSlide)
  }

  processStickyMove() {
    const leftSlideBorder = this.totalSlideWidth(this.currentSlide - 1);
    const rightSlideBorder = this.totalSlideWidth(this.currentSlide);

    const distanceToLeftBorder = Math.abs(-this.translate - leftSlideBorder);
    const distanceToRightBorder = Math.abs(-this.translate - rightSlideBorder);

    //console.log('distanceToLeftBorder:', distanceToLeftBorder, 'distanceToRightBorder:', distanceToRightBorder, 'this.currentSlide:', this.currentSlide, 'this.translate:', this.translate)

    if (distanceToLeftBorder < distanceToRightBorder) {
      this.translate = -leftSlideBorder;
    } else {
      this.translate = -rightSlideBorder;
    }

    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

    //console.log('currentSlide distanceToLeftBorder distanceToRightBorder', this.currentSlide, distanceToLeftBorder, distanceToRightBorder)
  }

  processMove() {
    const maxTranslate = -(this.totalSlidesWidth - this.swiperContainer.nativeElement.clientWidth);
    //console.log('maxTranslate this.translate', maxTranslate, this.translate)

    if (this.translate >= 0) {

      if (this.swiperConfig.loop) {
        this.translate = 0;
        this.translate -= this.slidesWidth[this.slidesWidth.length - 1];
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveLastSlideToFront();
        this.slidesWidth.unshift(this.slidesWidth.pop() as number);

        this.calculateCurrentSlide(this.translate);
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        
      } else if (this.swiperConfig.stretch) {
        if (this.translate >= this.swiperConfig.stretchOffsetLeft) {
          this.translate = this.swiperConfig.stretchOffsetLeft;

          this.calculateCurrentSlide(0);
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        } else {
          this.calculateCurrentSlide(0);
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        }

      } else {
        this.translate = 0;

        this.calculateCurrentSlide(this.translate);
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
      }

    } else if (this.translate <= maxTranslate) {

      if (this.swiperConfig.loop) {
        this.translate = maxTranslate;
        this.translate += this.slidesWidth[0];
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

        this.moveFirstSlideToEnd();
        this.slidesWidth.push(this.slidesWidth.shift() as number);

        this.calculateCurrentSlide(this.translate);
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);

      } else if (this.swiperConfig.stretch) {
        if (this.translate <= maxTranslate - this.swiperConfig.stretchOffsetRight) {
          this.translate = maxTranslate - this.swiperConfig.stretchOffsetRight;

          this.calculateCurrentSlide(this.translate);
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        } else {
          this.calculateCurrentSlide(this.translate);
          this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
        }

      } else {
        this.translate = maxTranslate;

        this.calculateCurrentSlide(this.translate);
        this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
      }

    } else {
      this.calculateCurrentSlide(this.translate);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
    }
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* Data Movement */

  private moveLastSlideToFront() {
    const lastSlide = this.swiperWrapper.nativeElement.lastElementChild;
    this.renderer.insertBefore(this.swiperWrapper.nativeElement, lastSlide, this.swiperWrapper.nativeElement.firstElementChild);
  }

  private moveFirstSlideToEnd() {
    const firstSlide = this.swiperWrapper.nativeElement.firstElementChild;
    this.renderer.appendChild(this.swiperWrapper.nativeElement, firstSlide);
  }

  private moveItemsToFront(count: number) {
    for (let i = 0; i < count; i++) {
      this.moveLastSlideToFront();
      this.slidesWidth.unshift(this.slidesWidth.pop()!);
    }
  }

  private moveItemsToBack(count: number) {
    for (let i = 0; i < count; i++) {
      this.moveFirstSlideToEnd();
      this.slidesWidth.push(this.slidesWidth.shift()!);
    }
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* Button's swipe process */

  private calculateGapEffect() {
    return this.swiperConfig.swipeToMove * parseInt(this.swiperConfig.swiperBodyWrapper.gap);
  }

  private calculateButtonSwipeTranslate(from: number, to: number) {
    return this.slidesWidth.slice(from, to).reduce((acc, val) => acc + val, 0)
  }

  private setTranslateWithAnimation() {
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'transform 0.3s ease-in-out');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
  }

  private setTranslateWithOutAnimation() {
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transition', 'none');
    this.renderer.setStyle(this.swiperWrapper.nativeElement, 'transform', `translateX(${this.translate}px)`);
  }


  swipeToFront() {
    const endSlide = this.currentSlide - this.swiperConfig.swipeToMove;
    
    if (endSlide >= 0) {
      this.translate += this.calculateButtonSwipeTranslate(endSlide, this.currentSlide) + this.calculateGapEffect();

      this.setTranslateWithAnimation(); 
      this.currentSlide = endSlide;

    } else {
      this.translate -= this.calculateButtonSwipeTranslate(this.slideAmount - this.swiperConfig.swipeToMove, this.slideAmount) + this.calculateGapEffect();
      
      this.setTranslateWithOutAnimation();
      this.moveItemsToFront(this.swiperConfig.swipeToMove);

      setTimeout(() => {
        this.translate += this.calculateButtonSwipeTranslate(this.currentSlide, this.swiperConfig.swipeToMove) + this.calculateGapEffect();
        this.setTranslateWithAnimation();
      }, 10);        
    }
  }

  swipeToBack() {
    const endSlide = this.currentSlide + this.swiperConfig.swipeToMove;
    const isContainerEnd = this.calculateButtonSwipeTranslate(endSlide, this.slideAmount) < this.swiperContainerWidth;

    if ((endSlide <= this.slideAmount - this.swiperConfig.swipeToMove) && !isContainerEnd) {
      this.translate -= this.calculateButtonSwipeTranslate(this.currentSlide, endSlide) + this.calculateGapEffect();

      this.setTranslateWithAnimation(); 
      this.currentSlide = endSlide;

    } else {
      this.translate += this.calculateButtonSwipeTranslate(0, this.swiperConfig.swipeToMove) + this.calculateGapEffect();

      this.setTranslateWithOutAnimation();
      this.moveItemsToBack(this.swiperConfig.swipeToMove);

      setTimeout(() => {
        this.translate -= this.calculateButtonSwipeTranslate(this.currentSlide, endSlide) + this.calculateGapEffect();
        this.setTranslateWithAnimation();
      }, 10);        
    }
  }

  /* -------------------------------------------------------------------------------------------------------------------------- */

  /* Initialization */

  setConfig(swiperConfig: SwiperConfiguration) {
    //console.log("SwiperConfiguration =>", swiperConfig);

    if (swiperConfig) {
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'width', swiperConfig.swiperBodyWrapper.width);
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'height', swiperConfig.swiperBodyWrapper.height);
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'left', swiperConfig.swiperBodyWrapper.left);
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'background-color', swiperConfig.swiperWrapper.backgroundColor);
      this.renderer.setStyle(this.swiperContainer.nativeElement, 'border-radius', swiperConfig.swiperWrapper.borderRadius);

      //this.renderer.setStyle(this.swiperWrapper.nativeElement, 'height', swiperConfig.swiperBodyWrapper.swiperWrapperHeight);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'gap', swiperConfig.swiperBodyWrapper.gap);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'align-items', swiperConfig.swiperBodyWrapper.aliginItems);
      this.renderer.setStyle(this.swiperWrapper.nativeElement, 'padding', swiperConfig.swiperBodyWrapper.padding);
    }
  }

  calculateTotalWidth() {
    const slides = this.swiperWrapperElement.nativeElement.children;
    //console.log('slides', slides.length);

    if (slides.length === 0) {
      setTimeout(() => {
        this.calculateTotalWidth();
      }, 100);
      return;
    }

    let totalWidth = 0;
    this.slidesWidth = [];

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];
      const slideWidth = slide.getBoundingClientRect().width; //slide.scrollWidth//

      totalWidth += slideWidth;
      this.slidesWidth.push(slideWidth);
    }

    totalWidth += (slides.length - 1) * parseFloat(this.swiperConfig.swiperBodyWrapper.gap);

    this.slideAmount = slides.length;
    this.totalSlidesWidth = Math.round(totalWidth);

    this.swiperContainerWidth = this.swiperContainer.nativeElement.getBoundingClientRect().width;
    //console.log('swiperContainerWidth', this.swiperContainerWidth);
    //console.log('Total width of slides:', this.totalSlidesWidth);
    //console.log('Total width of slides:', this.slidesWidth);
  }

  checkImagesLoaded() {
    const images = this.swiperWrapper.nativeElement.querySelectorAll('img');
    //console.log('images', images)
    const imagePromises = Array.from(images).map((img: HTMLImageElement) => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          //console.log('img', img)
          img.onload = () => resolve();
          img.onerror = () => reject();
        }
      });
    });

    Promise.all(imagePromises).then(() => {
      this.debouncedCalculateTotalWidth();
    }).catch(() => {
      console.error('One or more images failed to load.');
      this.debouncedCalculateTotalWidth();
    });
  }
}

<ng-container *ngIf="userMessagesService.isOpendNewMessageInfoBox() | async">
  <div class="notification" *ngIf="!isClosed && messageCount > 0" (click)="accessInbox()">
    <div class="notification-img-wrapper">
      <img src="assets/icons/icon-email-notification.png" alt="Email notification">
      <span class="notification-count">{{ messageCount }}</span>
    </div>
    <div class="notification-text-wrapper">
      <span class="notification-text" i18n>YOU HAVE {{messageCount}} NEW MESSAGES</span>
      <span class="notification-action" i18n>Click to access your Inbox</span>
    </div>
    <img src="assets/icons/icon-close-borderless.svg" class="notification-close" (click)="closeNotification()" alt="Close">
  </div>
</ng-container>
import { Injectable } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';
import { Location } from '@angular/common';
import { NOT_A_LINK_BREADCRUMB } from 'src/app/utils/not-a-link-breadcrumbs';
import { gameInfoTrx, providerTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';

@Injectable({
  providedIn: 'any',
})
export class NavigationService {
  public subjectPrevUrl = new BehaviorSubject<string>('');
  private urlHistory: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
    private translationConfig: TranslationConfig
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart)
      )
      .subscribe((event) => {
        const urlFrom = this.location.path() || '/';
        const currentUrl = (event as RouterEvent).url || '/';
        if (currentUrl !== urlFrom) {
          if (urlFrom.includes(currentUrl) && currentUrl !== urlFrom) {
            this.urlHistory.pop();
          } else if (this.urlHistory.includes(currentUrl)) {
            const index = this.urlHistory.indexOf(currentUrl);
            if (index !== -1) {
              this.urlHistory.splice(index, this.urlHistory.length - index);
            }
          } else {
            this.urlHistory = this.urlHistory.includes(urlFrom) || urlFrom.includes(translationConfig.getTranslation(gameInfoTrx)) ? this.urlHistory : [...this.urlHistory, urlFrom];
          }
        } else {
          if (currentUrl !== '/') {
            const backUrlBreadcrumbs = currentUrl.split('/').slice(0, -1);
            for (const breadcrumb of backUrlBreadcrumbs) {
              let subUrl = breadcrumb || '/';
              if (!NOT_A_LINK_BREADCRUMB.some(breadcrumb => subUrl === breadcrumb || subUrl === translationConfig.getTranslation(breadcrumb))) {
                this.urlHistory = [...this.urlHistory, subUrl];
              }
            }
          } else {
            this.urlHistory = [...this.urlHistory, '/'];
          }
        }
        this.subjectPrevUrl.next(this.urlHistory[this.urlHistory.length - 1] || '/');
      });
  }
}

import { HttpResponse, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i0 from '@angular/core';
import { makeStateKey, Injectable, ApplicationRef, TransferState, NgModule, APP_INITIALIZER } from '@angular/core';
import { of } from 'rxjs';
import { first, defaultIfEmpty, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
function getHeadersMap(headers) {
  const headersMap = {};
  for (const key of headers.keys()) {
    const values = headers.getAll(key);
    if (values !== null) {
      headersMap[key] = values;
    }
  }
  return headersMap;
}
/**
 * @deprecated Use `provideClientHydration` instead which caches HTTP requests by default.
 * @see https://angular.io/api/platform-browser/provideClientHydration
 */
class TransferHttpCacheInterceptor {
  makeCacheKey(method, url, params, responseType) {
    // make the params encoded same as a url so it's easy to identify
    const encodedParams = params.keys().sort().map(k => `${k}=${params.getAll(k)}`).join('&');
    const key = (method === 'GET' ? 'G.' : 'H.') + responseType + '.' + url + '?' + encodedParams;
    return makeStateKey(key);
  }
  constructor(appRef, transferState) {
    this.transferState = transferState;
    this.isCacheActive = true;
    // Stop using the cache if the application has stabilized, indicating initial rendering is
    // complete.
    appRef.isStable.pipe(first(isStable => isStable), defaultIfEmpty(false)).subscribe(() => {
      this.isCacheActive = false;
    });
  }
  intercept(req, next) {
    if (!this.isCacheActive || req.method !== 'GET' && req.method !== 'HEAD') {
      // Cache is no longer active or method is not HEAD or GET.
      // Pass the request through.
      return next.handle(req);
    }
    const storeKey = this.makeCacheKey(req.method, req.url, req.params, req.responseType);
    if (this.transferState.hasKey(storeKey)) {
      // Request found in cache. Respond using it.
      const response = this.transferState.get(storeKey, null);
      let body = response?.body;
      switch (response?.responseType) {
        case 'arraybuffer':
          body = new TextEncoder().encode(response.body).buffer;
          break;
        case 'blob':
          body = new Blob([response.body]);
          break;
      }
      return of(new HttpResponse({
        body,
        headers: new HttpHeaders(response?.headers),
        status: response?.status,
        statusText: response?.statusText,
        url: response?.url
      }));
    } else {
      // Request not found in cache. Make the request and cache it.
      const httpEvent = next.handle(req);
      return httpEvent.pipe(tap(event => {
        if (event instanceof HttpResponse) {
          this.transferState.set(storeKey, {
            body: event.body,
            headers: getHeadersMap(event.headers),
            status: event.status,
            statusText: event.statusText,
            url: event.url || '',
            responseType: req.responseType
          });
        }
      }));
    }
  }
  static {
    this.ɵfac = function TransferHttpCacheInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TransferHttpCacheInterceptor)(i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.TransferState));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TransferHttpCacheInterceptor,
      factory: TransferHttpCacheInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TransferHttpCacheInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ApplicationRef
    }, {
      type: i0.TransferState
    }];
  }, null);
})();
/**
 * An NgModule used in conjunction with `ServerTransferHttpCacheModule` to transfer cached HTTP
 * calls from the server to the client application.
 */
class TransferHttpCacheModule {
  static {
    this.ɵfac = function TransferHttpCacheModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TransferHttpCacheModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TransferHttpCacheModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ApplicationRef, TransferState, TransferHttpCacheInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useExisting: TransferHttpCacheInterceptor,
        multi: true
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TransferHttpCacheModule, [{
    type: NgModule,
    args: [{
      providers: [ApplicationRef, TransferState, TransferHttpCacheInterceptor, {
        provide: HTTP_INTERCEPTORS,
        useExisting: TransferHttpCacheInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * @deprecated Use `provideClientHydration` instead which caches HTTP requests by default.
 * @see https://angular.io/api/platform-browser/provideClientHydration
 */
function domContentLoadedFactory(doc) {
  return () => new Promise((resolve, _reject) => {
    if (doc.readyState === 'complete' || doc.readyState === 'interactive') {
      resolve();
      return;
    }
    const contentLoaded = () => {
      doc.removeEventListener('DOMContentLoaded', contentLoaded);
      resolve();
    };
    doc.addEventListener('DOMContentLoaded', contentLoaded);
  });
}
/**
 * @deprecated Use `provideClientHydration` instead which caches HTTP requests by default.
 * @see https://angular.io/api/platform-browser/provideClientHydration
 */
class StateTransferInitializerModule {
  static {
    this.ɵfac = function StateTransferInitializerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StateTransferInitializerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: StateTransferInitializerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: domContentLoadedFactory,
        deps: [DOCUMENT]
      }]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateTransferInitializerModule, [{
    type: NgModule,
    args: [{
      providers: [{
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: domContentLoadedFactory,
        deps: [DOCUMENT]
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { StateTransferInitializerModule, TransferHttpCacheModule, TransferHttpCacheInterceptor as ɵTransferHttpCacheInterceptor };

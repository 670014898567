<div class="categoryNameContainer">
    <div fxLayoutAlign="center center" class="category-title" i18n>Top Operators</div>
</div>
<div>
    <swiper fxFlex="auto" class="operator-swiper" [config]="config">
        <div *ngFor="let category of data; let first= first" fxLayoutAlign="center center" fxLayout="column" class="game-oeprator-container">
            <a [routerLink]="['', gameTrx, category.name]">
                <div class="thumbnail" fxLayout="row" fxLayoutAlign="center center">
                    <img src="{{category?.thumbnail?.url}}" alt="{{category?.displayName}}" />
                </div>
                <div class="oeprator-name-container" fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayoutAlign="center center" class="operator-name">{{category?.displayName}}</div>
                </div>
            </a>
        </div>
    </swiper>
</div>

<div class="profile-body" fxLayoutAlign="center center" [topDrawerRouterLink]="profileUrl">
    <div fxFlex="20" class="info" fxLayoutAlign="start center" fxLayout="row">
        <div class="profile-avatar" fxLayoutAlign="center start" fxLayout="column">
            <div class="thumbnail">
                <ng-container *ngIf="avatar">
                    <img src="{{avatar}}" />
                </ng-container>
            </div>
        </div>
    </div>
    <div fxFlex="80" class="progress-container" fxLayoutAlign="start center" fxLayout="column">
        <div class="label" fxLayoutGap="5px" fxLayoutAlign="center center" fxLayout="row">
            <div class="entry" i18n>level</div>
            <div class="entry">
                {{gamificationLevel}}
            </div>
            <div class="entry">
                {{gamificationPercentFill?.toFixed(1)}}%
            </div>
        </div>
        <mat-progress-bar mode="determinate" color="accent" [value]="gamificationPercentFill"></mat-progress-bar>
    </div>
    
</div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { gameTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { SwiperOptions } from 'swiper';
import { BasePageComponent } from '../../base-page/base-page.component';

@Component({
  selector: 'app-category-scroll',
  templateUrl: './category-scroll.component.html',
  styleUrls: ['./category-scroll.component.scss']
})
export class CategoryScrollComponent extends BasePageComponent {

  constructor(
    protected translationConfig: TranslationConfig,
    private router: Router,
    private deviceDetectService: DeviceDetectorService
  ) { super() }

  config: SwiperOptions = {
    direction: 'horizontal',
    slideToClickedSlide: false,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    keyboard: true,
    freeMode: true,
    slidesPerView: this.deviceDetectService.isDesktop() ? 5.3 : 2.4,
    centeredSlides: false,
    loop: false,
    simulateTouch: true,
    roundLengths: true,
    spaceBetween: 10
  };

  public readonly gameTrx: string = this.translationConfig.getTranslation(gameTrx);

  ngOnInit(): void {
  }

  openCategory(category) {
    this.router.navigate(['', this.gameTrx, category.name]);
  }

}

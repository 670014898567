<div class="categories-head">
    <div class="categories-title">
      <img [src]="iconUrl" [alt]="{displayName}" />
      <h3>{{displayName}}</h3>
    </div>
    <div class="categories-nav">
      <a i18n [routerLink]="link">See All</a>
      <button class="swipe-btn" (click)="swipeToFront()"><img style="transform: rotate(180deg);" src="assets/desktop/yellow-arrow.svg"></button>
      <button class="swipe-btn" (click)="swipeToBack()"><img src="assets/desktop/yellow-arrow.svg"></button>
    </div>
</div>
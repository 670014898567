<div *ngIf="hasPrizes else noprizes">
    <img class="box" src="assets/icons/icon-treasure-box-closed.png" />
</div>

<ng-template #noprizes>
    <div class="item" fxLayout="column" fxLayoutAlign="start center">
        <div class="vip-avatar" *ngIf="userRole else regularAvatar">
            <div class="vip-img-content">
              <img class="vip-player-img" src="{{avatar}}" />
              <div class="role" i18n>vip</div>
            </div>
            <img class="vip-leef" src="{{licenceService.getKey('icon-vip-leef')}}" >
        </div>
        <ng-template #regularAvatar>
          <div class="thumbnail">
              <img class="avatar" src="{{avatar}}" />
          </div>
        </ng-template>
        <div class="name" i18n>profile</div>
    </div>
</ng-template>
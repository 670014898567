<div *ngIf="!isMaintenance else maintenance" class="full-wrapper" [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'" #mainbackground>

  <div class="body-wrapper" [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'">
    <ng-container *ngIf="!detectDeviceService.isDesktop() else desktop">
      <ng-container *ngIf="isRegularPage else gamemode">
        <mat-sidenav-container class="top-menu-container" (backdropClick)="close()">
          <mat-sidenav #sidenav mode="over" fixedInViewport="true" disableClose>
            <app-top-menu (closeNav)="close()" [isVisible]="isOpened"></app-top-menu>
          </mat-sidenav>
          <mat-sidenav-content>
            <div class="main-container-content-wrapper">
              <ng-container *ngIf="!isFullPage else topContainer"></ng-container>

              <div class="content-wrapper" [ngClass]="{'full': !isFullPage }">
                <router-outlet></router-outlet>
              </div>

              <ng-container *ngIf="!isFullPage else bottomContainer"></ng-container>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<app-loader></app-loader>

<ng-template #topContainer>
  <div class="top-container-wrapper">
    <div class="top-container mobile">
      <app-download-infobar (close)="hideInstallPromotion()" (showInstall)="showInstallPopup()" *ngIf="isPromotion"></app-download-infobar>
      <app-install-popup *ngIf="showInstall" [pwaEvent]="installEvent" (showInstall)="showInstallPopup()" (hideInstall)="hideInstallPopup()"></app-install-popup>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="top-menu">
        <div class="logo-wrapper" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px"> 
          <app-top-menu-button (clicked)="topClicked($event)"></app-top-menu-button>
          <div fxHide.gt-md fxFlexAlign="center" class="top-logo" fxLayoutAlign="start start">
            <a [routerLink]="['/']"><img src="{{licenceService.getKey('logo-mobile')}}" alt="logo-green" /></a>
          </div>
        </div>
        <div class="flex-spacer"></div>
        <div class="profile-wrapper">
          <app-user-profile></app-user-profile>
        </div>
        <div class="auth-wrapper">
          <app-main-auth></app-main-auth>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bottomContainer>
  <app-footer *ngIf="isRegularPage"></app-footer>
  <ng-container *loggedRender>
    <app-notifications-email></app-notifications-email>
  </ng-container>
  <!-- <ng-container *ngIf="isToolBar">
    <app-bottom-toolbar *ngIf="!displayGamificationProgress else gamificationProgress"></app-bottom-toolbar>
  </ng-container> -->
  <app-bottom-toolbar *ngIf="!displayGamificationProgress else gamificationProgress"></app-bottom-toolbar>
</ng-template>

<ng-template #gamemode>
  <router-outlet></router-outlet>
</ng-template>

<ng-template #maintenance>
  <div class="maintenance" fxLayoutAlign="center center" fxLayout="row">
    <div class="lemon-maintanace-icon">
      <img class="lemon-screwdriver" src="assets/maintainance-assets/img-static-screwdriver.png" alt="maintenance-logo" />
      <img class="lemon-wrench" src="assets/maintainance-assets/img-static-wrench.png" alt="maintenance-logo" />
      <img class="lemon-roller-1" src="assets/maintainance-assets/img-rotating-cog-1.png" alt="maintenance-logo" />
      <img class="lemon-roller-2" src="assets/maintainance-assets/img-rotating-cog-2.png" alt="maintenance-logo" />
      <img class="lemon-roller-3" src="assets/maintainance-assets/img-rotating-cog-3.png" alt="maintenance-logo" />
    </div>
    <div class="lemon-maintanace-text">
      <div class="msg" i18n>Maintenance...</div>
      <p i18n>Lemon Casino is temporarily in beauty sleep mode! We are preparing new lemon tricks!</p>
    </div>
  </div>
</ng-template>

<ng-template #desktop>
  <app-desktop-front-page></app-desktop-front-page>
  <!-- <router-outlet></router-outlet> -->
</ng-template>

<ng-template #gamificationProgress>
  <app-gamification-progress></app-gamification-progress>
</ng-template>

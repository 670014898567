<div class="responsible-gaming-body center" fxLayoutAdivgn="center center">

    <div class="change-password-body center" fxLayoutAdivgn="center center">
        <h1 class="page-header">{{page?.header}}
        </h1>
        <div [innerHTML]="body">
        </div>
    </div>
    <div class="form-wrapper" fxLayoutAdivgn="center center">
        <form name="limitForm" [formGroup]="limitsForm">
            <div class="category-title-h1" i18n>Deposit</div>
            <ng-container [ngTemplateOutlet]="limit" [ngTemplateOutletContext]="{limitList:depositLimits, typeName:'deposit'}">
            </ng-container>
            <div class="buttons">
                <div><button mat-flat-button color="warn" type="submit" (click)="onSubmit()" [disabled]="!limitsForm.valid && !limitsForm.disabled" i18n>submit</button>
                </div>
            </div>

            <div class="category-title-h1" i18n>Wager</div>
            <ng-container [ngTemplateOutlet]="limit" [ngTemplateOutletContext]="{limitList:wagerLimits, typeName:'wager'}">
            </ng-container>
            <div class="buttons">
                <div><button mat-flat-button color="warn" type="submit" (click)="onSubmit()" [disabled]="!limitsForm.valid && !limitsForm.disabled" i18n>submit</button>
                </div>
            </div>

            <div class="category-title-h1" i18n>Loss</div>
            <ng-container [ngTemplateOutlet]="limit" [ngTemplateOutletContext]="{limitList:lossLimits, typeName:'loss'}">
            </ng-container>
            <div class="buttons">
                <div><button mat-flat-button color="warn" type="submit" (click)="onSubmit()" [disabled]="!limitsForm.valid && !limitsForm.disabled" i18n>submit</button>
                </div>
            </div>
        </form>
    </div>
</div>

<ng-template #limit let-limitList='limitList' let-typeName="typeName">
    <table mat-table class="limitTable standardTable" [dataSource]="limitList">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef i18n>Period</th>
            <td mat-cell *matCellDef="let element"> {{limitNameTranslation[element.period]}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef i18n>Used</th>
            <td mat-cell *matCellDef="let element"> {{element.sum}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef i18n>Current value</th>
            <td mat-cell *matCellDef="let element" (click)="editValue(element)">
                <ng-container [ngSwitch]="( element === selectedElement )">
                    <mat-form-field *ngSwitchCase="true" [formGroup]="limitsForm" class="example-form-field">
                        <mat-label i18n>Enter value</mat-label>
                        <input matInput type="text" inputmode="numeric" pattern="[0-9]*" maxlength="10" formControlName="{{element.type}}_{{element.period}}" autofocus type="text">
                    </mat-form-field>
                    <ng-container *ngSwitchCase="false">{{limitsForm.get(element.type+'_'+element.period).value ||
                        unlimitedTest}}
                        <mat-icon class="small">edit</mat-icon>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>

        <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef i18n>Pending</th>
            <td mat-cell *matCellDef="let element"> {{element.future_value || '-'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>
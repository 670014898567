import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';
import { Observable } from 'rxjs';
import { depositTrx, paymentTrx } from 'src/app/router-translation.labels';
import { Router } from '@angular/router';
import { TranslationConfig } from 'src/app/utils/translate-config';

@Component({
  selector: 'app-top-menu-balance',
  templateUrl: './top-menu-balance.component.html',
  styleUrls: ['./top-menu-balance.component.scss']
})
export class TopMenuBalanceComponent implements OnInit {

  constructor(
    private balanceService: BalanceStatusService,
    private router: Router,
    protected translationConfig: TranslationConfig
  ) { }

  @Output() closeEventDeposit = new EventEmitter();


  balanceObs$: Observable<BalanceInAppInfo>;
  paymentUrl: string = this.translationConfig.getTranslation(paymentTrx) + '/' + this.translationConfig.getTranslation(depositTrx);

  ngOnInit(): void {
    this.balanceObs$ = this.balanceService.getBalanceInfoStatus();
  }

  deposit() {
    this.closeEventDeposit.next("");
    this.router.navigate(['/' + this.paymentUrl]);
  }

}

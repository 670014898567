import { Component } from '@angular/core';
import { LastPlayedService } from '../../../services/profile/last-played.service';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from '../../base-page/base-page.component';
import { takeUntil } from 'rxjs/operators';
import { lastPlayedFullTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-last-played',
  templateUrl: './last-played.component.html',
  styleUrls: ['./last-played.component.scss']
})
export class LastPlayedComponent extends BasePageComponent {

  constructor(
    public lastPlayedService: LastPlayedService,
    public detectDesktop: DetectDeviceService

  ) { super() }

  categoryName: string = $localize`:@@lastPlayedCategory:last played`;
  linkUrl: string = lastPlayedFullTrx;

  games: GameItem[];

  ngOnInit(): void {
    this.lastPlayedService.getLastPlayed().pipe(takeUntil(this.unsubscribe)).subscribe(
      resp => {
        if (this.detectDesktop.isDesktop()) {
          this.games = resp?.slice(0, 4);
        } else {
          this.games = resp?.slice(0, 3);
        }
      }
    )
  }

}

<mat-toolbar *ngIf="!isHidden" color="primary" class="sticky">
  <mat-toolbar-row>
    <ng-container *appShellNoRender>
      <div *ngIf="isLogged && unreadMessages > 0; else profileButton" class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/'+inboxUrl]">
        <div class="thumbnail">
          <img src="assets/icons/bottom-nav/icon_email.svg" alt="inbox" />
          <div class="inbox-pulse"></div>
        </div>
        <div class="name" i18n>inbox</div>
      </div>
    </ng-container>

    <div *ngIf="!isLogged" class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/'+singnUrl]">
      <div class="thumbnail">
        <img src="assets/icons/bottom-nav/icon_signup.svg"/>
        <div class="signup-pulse"></div>
      </div>
      <div class="name" i18n>sign up</div>
    </div>
    <span fxFlex></span>
    <div class="item" fxLayout="column" fxLayoutAlign="start center" (click)="catagoryRedirect()">
      <div class="thumbnail">
        <img src="assets/icons/bottom-nav/icon_categories.svg" alt="slots" />
      </div>
      <div class="name" i18n>categories</div>
    </div>
    <span fxFlex></span>
    <div class="item deposit-btn" fxLayout="column" fxLayoutAlign="center center" [routerLink]="['/'+paymentUrl]">
      <img src="assets/icons/icon-wallet-sticky.svg" alt="deposit" class="middle" />
    </div>
    <span fxFlex></span>
    <div class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/'+liveCasinoUrl]">
      <div class="thumbnail">
        <img src="assets/icons/bottom-nav/icon_ic.svg" alt="live-casino" />
        <!-- <mat-icon>money</mat-icon> -->
      </div>
      <div class="name" i18n>Live casino</div>
    </div>
    <span fxFlex></span>
    <div class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/'+searchUrl]">
      <div class="thumbnail">
        <img src="assets/icons/bottom-nav/icon_search.svg" alt="search" />
      </div>
      <div class="name" i18n>search</div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #profileButton>
  <div *ngIf="isLogged" class="item" fxLayout="column" fxLayoutAlign="start center" [routerLink]="['/client-area/main']">
    <div class="profile-thumbnail">
      <app-profile-button></app-profile-button>
    </div>
  </div>
</ng-template>

import { AfterViewInit, Component, Inject } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NotificationService } from 'src/app/services/notofications/notification.service';

@Component({
  selector: 'app-notification-sheet',
  templateUrl: './notification-sheet.component.html',
  styleUrls: ['./notification-sheet.component.scss']
})
export class NotificationSheetComponent extends BasePageComponent implements AfterViewInit {

  constructor(private _bottomSheetRef: MatBottomSheetRef<NotificationSheetComponent>,
    private notificationService: NotificationService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public notifications: Notification[],
    ) {super()}

  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void{
    this.notificationService.setNotificationSeen();
  }





}

import { Component, Inject, LOCALE_ID } from '@angular/core';
import { InitialDataService } from 'src/app/services/page/initial-data.service';
import { takeUntil } from 'rxjs/operators';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from 'src/app/global.tokens';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Language } from 'src/app/apollo/models/base-models';
import { FooterComponent } from '../../footer/footer.component';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';
import { providerTrx } from 'src/app/router-translation.labels';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-desktop-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class DesktopFooterComponent extends FooterComponent {
  isReadMore: boolean = true;
  height: any = '145px';

  constructor(
    public initialData: InitialDataService,
    protected location: Location,
    public deviceDetectService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    protected cookie: CookieService,
    protected router: Router,
    protected manipulateHeaderService: HeadManipulateService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName,
  ) {
    super(
      initialData,
      location,
      deviceDetectService,
      translationConfig,
      cookie,
      manipulateHeaderService,
      locale,
      hostName
    )
  }

  public providerUrl: string = this.translationConfig.getTranslation(providerTrx);

  showMoreTrx = $localize`:@@button-show-more:Read more`;
  showLessTrx = $localize`:@@button-show-less:Read less`;

  childData: any;
  currentUrl: string;
  selectedLanguage: Language;

  ngOnInit(): void {
    // this.location.onUrlChange((url) => {
    //   let urlArray = url.split('/');
    //   if (urlArray[0].length == 2) {
    //     urlArray = urlArray.slice(1, urlArray.length);
    //   }
    //   this.currentUrl = urlArray.join('/');
    // })
    this.initializeSeoHandlers()

    this.childData = this.initialData.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
      this.childData = resp;
      this.selectedLanguage = resp.languages.filter(item => {
        return item.name === this.locale
      })[0];
    })

    if(environment.affiliateProgram) {
      this.affiliateUrl = environment.affiliateProgram;
    }
  }

  setCookie($event) {
    const language = ($event.value as string);
    this.cookie.set('language', language, 2592000, '/', this.hostName);
    window.location.href = '/' + language + '/';
  }

  toggleData() {
    this.isReadMore = !this.isReadMore
    if (!this.isReadMore) {
      this.height = '730px'
    } else {
      this.height = '145px'
    }
  }

}
